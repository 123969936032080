Vue.component('homeItemCardComponent',{
    props: {
        items: {
            type: Array,
            required: true
        },
        section: {
            type: Object,
            required: true
        }
    },
    data:function(){
        return {
            itemsPerPage:4,
            initPage:0,
            animate:true,
            interval:8000,
            animateEvent:null
        }
    },
    computed:{
        maxPages:function(){
            if(Array.isArray(this.items))
                return this.items.length
            return 0;
        },
        controlActivate:function(){
            return this.maxPages>this.itemsPerPage;
        }
    },
    methods:{
        itemCarouselMoveLeft:function(){
            if(this.initPage-1>=0)
                this.initPage-=1;
        },
        itemCarouselMoveRight:function(){
            if( this.initPage+1+this.itemsPerPage>this.maxPages)
                this.initPage=0;
            else
                this.initPage+=1;

        },
        animateCallback:function(){
            if(this.controlActivate) {
                this.itemCarouselMoveRight();
                this.animateEvent = setTimeout(this.animateCallback, this.interval);
            }
        },
        animateStop:function(){
            clearTimeout(this.animateEvent);
        }
    },
    mounted:function(){
        this.animateCallback.bind(this);
        this.$nextTick(function () {
            if(this.animate)
                this.animateCallback();
        });
    },
    template:`<div class="container-fluid" >
                <h2>{{section.title}} ({{items.length}})</h2>
                <div ref="homeItemCarousel" class="item-carousel">
                       <div class="item-carousel-inner row justify-content-center"  @mouseover="animateStop" @mouseleave="animateCallback">
                            <template v-for="item of items.slice(initPage,initPage+itemsPerPage)">
                              <div class="col l3 m6 s12">
                                   <div class="produktliste" >
                                        <itemViewModuleComponent :item="item" :showCrossOutPrice="true"></itemViewModuleComponent>
                                    </div>
                              </div>
                            </template>
                      </div>
                      <div class="row justify-content-center itemcard-control-container" v-if="controlActivate">
                          <a class="itemcard-control itemcard-control-prev" href="javascript:void(0)" :title="tr('Previous')" @click="itemCarouselMoveLeft" style="wi">
                                      <i class="fa fa-lg fa-chevron-left"></i>
                          </a>
                          <a class="itemcard-control itemcard-control-next" href="javascript:void(0)" :title="tr('Next')" @click="itemCarouselMoveRight">
                                    <i class="fa fa-lg fa-chevron-right"></i>
                          </a>
                      </div>
                </div>
              </div>`
});
